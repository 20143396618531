//import MyForm from './../Components/Form.js';

import React, { useState } from 'react';
import Form1 from './../Components/Form1.jsx';
import Form2 from './../Components/Form2.jsx';
import Form3 from './../Components/Form3.jsx';

const RECAPTCHA_V2_PUBLIC = process.env.REACT_APP_RECAPTCHA_V2_PUBLIC;

const Contact = () => {
 	const [selectedForm, setSelectedForm] = useState('form1'); // Default form
  
  const handleFormChange = (formType) => {
    setSelectedForm(formType);
  };

  return ( 
    <div className="form-wrap">
			<h2>Contact Page</h2>
			<div className="form-selection-wrap">
        <button className="form-selection-button" onClick={() => handleFormChange('form1')}>Kids Form</button>
        <button className="form-selection-button" onClick={() => handleFormChange('form2')}>Parent Form</button>
        <button className="form-selection-button" onClick={() => handleFormChange('form3')}>Couples Form</button>

      </div>
			<div className="form-content-wrap">
				{selectedForm === 'form1' && <Form1 RECAPTCHA_V2_PUBLIC={RECAPTCHA_V2_PUBLIC} />}
        {selectedForm === 'form2' && <Form2 RECAPTCHA_V2_PUBLIC={RECAPTCHA_V2_PUBLIC} />}
        {selectedForm === 'form3' && <Form3 RECAPTCHA_V2_PUBLIC={RECAPTCHA_V2_PUBLIC} />}

			</div>
    </div>
  );
 };
 



export default Contact;
