// Menu.js

import { Link } from 'react-router-dom';
import './Menu.css'; // Optional: for custom styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react';



const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="MenuWrap">
      {/* Full menu for larger screens */}
      <div className="full-menu">
        <nav className="menu">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/pdf-view">PDF View</Link></li>
            <li><Link to="/pdf-generate">PDF Generate</Link></li>
            <li><Link to="/about">About 2</Link></li>
            <li><Link to="/about">About 3</Link></li>
          </ul>
        </nav>
      </div>

      {/* Burger menu for smaller screens */}
      <div className="burger-menu">
        <div className="burger-icon">
          <FontAwesomeIcon icon="bars" size="lg" onClick={toggleMenu} />
        </div>
        {isMenuOpen && (
          <nav className="menu">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/pdf-view">PDF View</Link></li>
              <li><Link to="/pdf-generate">PDF Generate</Link></li>
              <li><Link to="/about">About 2</Link></li>
              <li><Link to="/about">About 33</Link></li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );

};

export default Menu;
