import './App.css';
import Home from './Pages/Home.jsx';
import About from './Pages/About.jsx';
import Contact from './Pages/Contact.jsx';
import PDF_View from './Pages/PDF_View.jsx';
import PDF_Generate from './Pages/PDF_Generate.jsx';
import Header from './Components/Header.jsx';
import Footer from './Components/Footer.jsx';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBars, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

//import { useEffect, useCallback } from 'react';



library.add(fab, faCheckSquare, faBars)

const App = () => {
  return (
      <Router>
        <MainLayout />
      </Router>
    );
};

const MainLayout = () => {

  return (
      <div className="MainLayoutWrap">
        <Header />

        <div className="ContentWrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pdf-view" element={<PDF_View />} />
            <Route path="/pdf-generate" element={<PDF_Generate />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </div /*End PageWrap*/> 
  );
};

export default App;
