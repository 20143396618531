import './Form.css';
import { useState, useRef } from 'react';
import Modal from './Modal.jsx'; // Import the Modal component

//import ReactDOM from 'react-dom/client';
import ReCAPTCHA from 'react-google-recaptcha'

function Form2( {RECAPTCHA_V2_PUBLIC} ) {
  const [inputs, setInputs] = useState("");
  const [errors, setErrors] = useState({});

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const recaptchaRef = useRef(null); // Reference to the reCAPTCHA component

  const validateForm = () => {
    const newErrors = {};
    if (!inputs.firstname) newErrors.firstname = 'First Name is required';
    if (!inputs.lastname) newErrors.lastname = 'Last Name is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setErrors((errors) => ({ ...errors, [name]: '' })); 
  }

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError(false); 

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      setModalTitle("Form Error");
      setModalMessage('Please fill out all required fields.');
      return; 
    }

    if (!recaptchaValue) {
      setRecaptchaError(true);
      setModalMessage('Please complete the reCaptcha.');
      return;
    }

     // Example of sending the form data along with reCAPTCHA token
    try {
      const response = await fetch('/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...inputs, recaptchaValue }),
      });

      const result = await response.json();
      //alert('Form submission result:' + result.message);
      setModalTitle("Server Response");
      setModalMessage(result.message);

      // reset form
      setInputs({
          firstname: '',
          lastname: '',
        });

      //reset var that tracks recaptcha state
      setRecaptchaValue(null);
      //reset recaptcha 
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      return;
    } catch (error) {
      //alert('Error during form submission:' + error);
      setModalTitle("Server Error");
      setModalMessage(error.message);
      return;

    }
    
  }

  const closeModal = () => {
    setModalMessage('');
    setModalTitle('');
  };

  return (
  <div>

    <form onSubmit={handleSubmit}>
      <h1>SignUp Form</h1>

      <label>First Name:
        {errors.firstname && <p className="form-error-text">{errors.firstname}</p>}
        <input 
          type="text" 
          name="firstname" 
          value={inputs.firstname || ""} 
          onChange={handleChange}
        />
      </label>

      <label>Last Name:
        {errors.lastname && <p className="form-error-text">{errors.lastname}</p>}
        <input 
          type="text" 
          name="lastname" 
          value={inputs.lastname || ""} 
          onChange={handleChange}
        />
      </label>

      <div className="recaptcha-wrap">
        {recaptchaError && <p className="form-error-text">reCAPTCHA is required</p>}

        <ReCAPTCHA
          sitekey={RECAPTCHA_V2_PUBLIC}
          onChange={handleRecaptchaChange}
          ref={recaptchaRef}
        />
      </div>

      <input type="submit" />

    </form>

    <Modal message={modalMessage} title={modalTitle} onClose={closeModal} />

    </div>


  )
}

export default Form2;
