import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Footer = () => {
  const year = new Date().getFullYear(); // Get the current year

  return ( 
    <footer>
    <h2>The Footer</h2>
      <div class="footer-container">
        <div class="footer-column">
          <h3>Sitemap</h3>
          <ul class="page-list">
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="footer-column">
          <h3>Resources</h3>
          <ul class="page-list">
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>

      <h3>Follow Us On...</h3>
      <a href="https://facebook.com/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook']} /></a>
      <a href="https://twitter.com/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
      <a href="https://instagram.com/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
      <a href="https://linkedin.com/company/myapp" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a>
      <p>© {year} My App</p>


    </footer>
  );
 };

export default Footer;