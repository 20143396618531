import './Header.css'; 
import Menu from './Menu.jsx';
import { useLocation} from 'react-router-dom';

const Header = () => {
	const location = useLocation();
	const shouldShowHeader = [ '/', '/contact', '/about', '/pdf-view', '/pdf-generate'].includes(location.pathname);

  return ( 
  	<header>
  		<img src="/abstract_wave.jpg" alt="Logo" className="header-image" />
  		{shouldShowHeader && <Menu/>}
  	</header>
  );
 };

export default Header;



